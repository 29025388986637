.dividerSpacing {
  margin: 0 0 var(--spacing-10);

  @media (--tablet-l) {
    margin: var(--spacing-16) 0;
  }

  @media (--desktop-l) {
    margin: var(--spacing-20) 0;
  }

  &[data-with-top-border='true'] {
    border-top: 1px solid var(--color-tarmac-grey-300);
    padding: var(--spacing-20) 0 var(--spacing-10) 0;
    margin-bottom: var(--spacing-10);

    @media (--mobile) {
      padding: var(--spacing-10) 0 0;
    }
  }
}
