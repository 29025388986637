.calculatorWrapper {
  position: relative;
  margin: 0 0 var(--spacing-10) 0;
  border: 1px solid var(--neutral-300-disabled, #b5b5b5);

  @media (--tablet-l) {
    margin: var(--spacing-6) 0 var(--spacing-12) 0;
  }

  & > *:not(:last-child) {
    margin: 0 0 var(--spacing-10) 0;

    @media (--tablet-l) {
      margin: var(--spacing-6) 0 var(--spacing-12) 0;
    }
  }

  --color-mica-blue-500: #164ca3;

  --color-primary-50: var(--color-mica-blue-50);
  --color-primary-100: var(--color-mica-blue-100);
  --color-primary-200: var(--color-mica-blue-200);
  --color-primary-300: var(--color-mica-blue-300);
  --color-primary-400: var(--color-mica-blue-400);
  --color-primary-500: var(--color-mica-blue-500);
  --color-primary-600: var(--color-mica-blue-600);
  --color-primary-700: var(--color-mica-blue-700);
  --color-primary-800: var(--color-mica-blue-800);
  --color-primary-900: var(--color-mica-blue-900);

  --color-secondary-50: var(--color-heycar-mint-50);
  --color-secondary-100: var(--color-heycar-mint-100);
  --color-secondary-200: var(--color-heycar-mint-200);
  --color-secondary-300: var(--color-heycar-mint-300);
  --color-secondary-400: var(--color-heycar-mint-400);
  --color-secondary-500: var(--color-heycar-mint-500);
  --color-secondary-600: var(--color-heycar-mint-600);
  --color-secondary-700: var(--color-heycar-mint-700);
  --color-secondary-800: var(--color-heycar-mint-800);
  --color-secondary-900: var(--color-heycar-mint-900);

  --button-primary-base-bg-color: var(--color-primary-500);
  --button-primary-hover-bg-color: var(--color-primary-600);
  --button-primary-active-bg-color: var(--color-primary-700);
}

.link {
  > span {
    color: var(--neutral-700-pressed-default-text, #303030);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
  }
}

.mainWrapper {
  position: relative;
  z-index: 1;
  max-width: 100%;
  padding: var(--spacing-10) var(--spacing-5);
  border-top: 10px solid var(--primary-50-surface, #f1f4fa);

  @media (--tablet) {
    padding: var(--spacing-10) var(--spacing-6);
  }

  @media (--tablet-l) {
    padding: var(--spacing-10) var(--spacing-8);
  }

  @media (--desktop-l) {
    padding: var(--spacing-10);
  }
}

.errorLabel {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 var(--spacing-4) 0 var(--spacing-4);
  line-height: 26px;
  @media (--tablet) {
    & p {
      line-height: 26px;
    }
  }
}

.mainOverlay {
  position: absolute;
  top: -9px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 103%;
  background: linear-gradient(to top, #fff1ef 50%, rgba(255, 255, 255, 0.8) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;

  @media (--mobile) {
    top: -12px;
    height: 102%;
  }
  @media (--tablet) {
    background: linear-gradient(to left, #fff1ef 50%, rgba(255, 255, 255, 0.8) 100%);
  }
}

.mainOverlay.visible {
  visibility: visible;
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.formContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: var(--spacing-8);

  @media (--tablet) {
    flex-direction: row;
    gap: var(--spacing-6);
  }

  @media (--tablet-l) {
    gap: var(--spacing-8);
  }

  @media (--desktop) {
    gap: var(--spacing-10);
  }
}

.content {
  flex: 1 1 200px;
  > div {
    margin-bottom: 0;
  }
}

.divider {
  border-top: 1px solid var(--color-tarmac-grey-300);
  @media (--tablet) {
    border-top: none;
    border-left: 1px solid var(--color-tarmac-grey-300);
  }
}

.summaryContainer {
  flex: 1 1 200px;
  & h5,
  & p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }
  & > *,
  & > p {
    margin-bottom: var(--spacing-5);
  }

  @media (--tablet) {
    max-width: calc(50% - 18px);
  }
}
.universalSummaryContainer {
  margin-top: var(--spacing-5);
  & h5,
  & p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }
  & > *,
  & > p {
    margin-bottom: var(--spacing-5);
  }
}

.summaryFakeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (--mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.ctasContainer {
  width: auto;
  text-align: center;

  & > :first-child {
    margin-bottom: var(--spacing-3);
    @media (--tablet) {
      margin-bottom: 0;
    }
  }
}

.universalCtasContainer {
  text-align: right;
  & > :first-child {
    margin-bottom: var(--spacing-3);
    @media (--tablet) {
      margin-bottom: 0;
    }
  }
}
.universalButton {
  width: 280px;
  @media (--mobile) {
    width: 100%;
  }
}

.summaryTitle {
  & [class*='typography'] {
    position: relative;
  }
}
.fakeSummary {
  height: 380px;
  position: relative;
}
.universalFakeSummary {
  height: 180px;
  position: relative;
}
.fakeCheckoutSummary {
  height: 180px;
  display: flex;
  flex-direction: row;
}
.fakeButton {
  height: 43px;
  width: 100%;
  position: relative;
}
.fakeButtons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  margin-bottom: var(--spacing-8) !important;
  max-width: 100%;
  position: relative;
}
.modalContainer {
  @media (--tablet-l) {
    height: 635px;
    width: unset !important;
  }
}
.emailModalContainer {
  @media (--tablet-l) {
    width: 800px;
    height: 330px;
  }
}
.modalBody {
  overflow: auto;
}
.error {
  flex: 1 1 200px;
  & div {
    background-color: var(--color-red-50);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0 auto var(--spacing-5);
    padding: var(--spacing-5);
  }
  & svg {
    margin: 0 auto;
  }
  & h5,
  & p,
  & svg {
    margin-bottom: var(--spacing-5);
    margin-top: 0;
  }
  & > *,
  & > p {
    margin-bottom: var(--spacing-5);
  }
  @media (--tablet) {
    max-width: calc(100% - 18px);
  }
}
.financePreApprovalWrapper {
  text-align: left;
}
.financePreApprovalTitle {
  margin: var(--spacing-4) 0 var(--spacing-2) 0;
  & > p {
    font-size: 16px;
  }
}
.financePreApprovalErrorIcon {
  text-align: center;
}
.financePreApprovalCta {
  margin-top: var(--spacing-5);
}
.lenderNote {
  display: flex;
  text-align: start;
  margin-bottom: var(--spacing-3) !important;
}
.supportOptIn {
  & label {
    margin: var(--spacing-5) 0;
    display: flex;
    gap: var(--spacing-3);
    align-items: start;
    user-select: none;
  }
  & p {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-6);
    font-weight: 500;
  }
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: var(--spacing-6);
  margin-bottom: -18px;
  font-weight: 500;

  p,
  div {
    margin-top: var(--spacing-2);
    font-weight: 500;
  }

  div {
    margin-top: var(--spacing-6);
  }

  @media (--mobile) {
    flex-direction: column;
    align-items: flex-start;

    p {
      margin-top: 0 !important;
    }
  }
}

.clickableSubheader {
  cursor: pointer;
  color: var(--color-primary-500);
  text-decoration: underline;

  @media (--mobile) {
    font-size: 14px;
    margin-top: 0px;
  }
}

.modalSpecialContainer {
  @media (--tablet-l) {
    height: 635px;
    width: 800px;
  }
}
.modalSpecialBody {
  overflow: auto;
  padding: var(--spacing-1) 50px;
  @media (--mobile) {
    padding-bottom: var(--spacing-5);
  }
}
.modalContent {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.footerContent {
  margin-top: var(--spacing-3);
  padding: 2px 20px;
  line-height: 22px;
}

.modalContentBody {
  flex: 1;
  font-size: 16px;
}

.footerContent {
  background-color: var(--color-primary-50);
  width: 350px;
}

@media (--mobile), (--tablet) {
  .modalContent {
    flex-direction: column;
  }

  .footerContent {
    width: 100%;
  }
}

@media (--tablet-l) {
  .modalContent {
    flex-direction: row;
  }

  .footerContent {
    width: 350px;
  }
}

.modalContent h3 {
  margin-bottom: var(--spacing-2);
}

.modalContent ul {
  list-style-type: disc;
  padding-left: var(--spacing-3);
}
