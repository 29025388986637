.headerContainer {
  margin-bottom: 20px;
  & h3,
  p {
    margin: 0 0 9px;
    position: relative;
  }
}
.header {
  & h4,
  & p {
    margin-bottom: var(--spacing-2);
    margin-top: 0;
  }
}

.link {
  color: var(--neutral-700-pressed-default-text, #303030);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
}

.products {
  margin-top: var(--spacing-3);
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.product {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  position: relative;

  & strong {
    font-weight: 500;
  }
}
.findOut {
  text-decoration-line: underline;
  cursor: pointer;
  color: var(--button-primary-base-bg-color);
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);

  & > p {
    flex: 1 1 60%;
    margin: 0;
  }
}
